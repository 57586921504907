angular.module('LeasePilot').directive('downloadSurveyModal', [
  '$rootScope',
  '$mdDialog',
  '$http',
  'LeaseEditorService',
  function($rootScope, $mdDialog, $http, LeaseEditorService) {
    return {
      restrict: 'E',
      templateUrl: 'download_survey_modal.html',
      scope: {
        lease: "=",
      },
      link: function(scope, element, attrs) {
        scope.done = false;
        scope.speed = null;
        scope.scale = 0;
        scope.comment = null;

        scope.cancel = function() {
          $mdDialog.cancel();
        };

        scope.selectSpeed = function(speed) {
          scope.speed = speed;
          scope.scale = 0;
          scope.comment = null;

          const data = {
            drafting_stats: {
              lease_id: window.lease.id,
              general_time_saved: scope.speed,
            },
          };
          $.post(`/api/drafting_stats`, data);
        }

        scope.selectScale = function(scale) {
          scope.scale = parseInt(scale);

          const data = {
            drafting_stats: {
              lease_id: window.lease.id,
              general_time_saved: scope.speed,
              percentage_time_saved: scope.scale,
            },
          };
          $.post(`/api/drafting_stats`, data);
        }

        scope.saveComment = function(comment) {
          scope.comment = comment;
        }

        scope.submit = function() {
          scope.done = true;

          const data = {
            drafting_stats: {
              lease_id: window.lease.id,
              general_time_saved: scope.speed,
              percentage_time_saved: scope.scale,
              comment: scope.comment,
            },
          };
          $.post(`/api/drafting_stats`, data);
        }
      },
    };
  },
]);
